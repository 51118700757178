import React from 'react'
import './styles.scss'

export default function TestimonialCard({img, quote, name}) {
    return(
        <div className='testimonial-container'>
            <img src={img} alt={name}></img>
            <div className='text'>
                <p className='name'>{name}</p>
                <p className='quote'>{quote}</p>
            </div>

        </div>
    )
}