import Button from "../../components/Button";
import Menu from "../../components/Menu";
import "./styles.scss";
import Services from "../../context/services";
import { useContext } from "react";
import ServiceCard from "../../components/ServiceCard";
import Footer from "../../components/Footer";
import TestimonialCard from "../../components/Testimonial";
import Testimonials from "../../context/testimonials";

export default function Home() {
  let contextServices = useContext(Services);
  let filtered = contextServices.filter((serv) => serv.mostrequested);
  let contextTestimonials = useContext(Testimonials);

  return (
    <>
      <Menu />
      <header>
        <div className="text-content">
          <h1>¡Hola!</h1>
          <p className="subtitle">
          Si estás aquí, es porque has dado el primer paso hacia una transformación interior significativa. No estás solo en esta travesía, estamos aquí para apoyarte y guiarte en cada paso. Juntos, enfrentaremos los desafíos y celebraremos tus triunfos.<br/><br/>
¡Elige tu camino y déjate llevar por la magia de la transformación interior! Tu felicidad te espera, y estamos ansiosos de ser parte de este increíble viaje junto a ti.
          </p>
          <a className="primary-button" href="#fav-services">
          ¡COMENZÁ AHORA!
          </a>
        </div>
      </header>
      <section id="welcome">
        <div>
        <h3>¿Quiénes somos?</h3>
        <p>
        ¡Hola! Aquí estamos, el equipo de asesoría energética que estabas buscando. Soy Leila Kattler, con más de 15 años de experiencia, y junto a mí está Alejandro, un talentoso sanador pránico enfocado en el ámbito clínico. También contamos con Soledad, nuestra experta administrativa, lista para responder a tus preguntas y asistirte en la reserva de servicios. Nuestra misión es guiarte hacia el equilibrio y bienestar a través de soluciones energéticas personalizadas. No dudes en conectarte con Soledad para cualquier consulta. ¡Te damos la bienvenida a nuestra comunidad energética, donde tu transformación es nuestro propósito principal!
        </p>
        <Button link={'/equipo'} text={"Conocé al equipo"} />
        </div>
        <div className="image"></div>
        
      </section>
      <section id="fav-services">
        <div>
        <h2>Servicios más pedidos</h2>
        <Button link={"/servicios"} text={"Ver mas servicios"} />
        </div>
        <div className="services-container">
          {filtered.map((service) => (
            <ServiceCard
              key={service.id}
              title={service.title}
              category={service.categoryColor}
              img={service.img}
              link={service.link}
            />
          ))}
        </div>
      </section>
      <section id="news">
        <h2>Noticias</h2>
        <div>
          <a href={"https://www.instagram.com/leilakattler/"} target="_blank">
            <img
              src="/images/internal-images/noticias-vivos.png"
              alt="Seguime en instagram"
            ></img>
            {/* <a
              className="primary-button"
              href={"https://www.instagram.com/leilakattler/"}
              target="_blank"
            >
              Seguime en instagram
            </a> */}
          </a>
          <a
            href={"https://chat.whatsapp.com/FLiI9kqrYlq0kya4Nt1e8h"}
            target="_blank"
          >
            <img
              src="/images/internal-images/noticias-whatsapp (2).png"
              alt="Unite a una comunidad espiritual"
            ></img>
            {/* <a
              className="primary-button"
              href={"https://chat.whatsapp.com/FLiI9kqrYlq0kya4Nt1e8h"}
              target="_blank"
            >
              ¡Me quiero unir!
            </a> */}
          </a>
        </div>
      </section>
      <section id="testimonials">
          <h2>Testimonios</h2>
          <div className="testimonials-container">
            {contextTestimonials.map((testimony) => (
              <TestimonialCard
                key={testimony.id}
                img={testimony.img}
                quote={testimony.quote}
                name={testimony.name}
              />
            ))}
          </div>
        </section>
      
      <Footer />
    </>
  );
}
