import React from "react";

const Testimonials = React.createContext([
  {
    id:1,
    img: "/images/testimonies/Ada-Guadalup_Testimonio.jpeg",
    quote: "...La atención, el profesionalismo y el seguimiento. Investigó mi deporte y los tipos de movimientos que hago. También sobre mi lesión y los estudios que me hicieron. Y con todo eso personalizó el tratamiento completamente para mi caso para poder recuperarme más rápido.",
    name: "Guadalupe Ada"
  },
  {
    id:2,
    img: "/images/testimonies/testimonios_alejandro_pappalardo-150x150.png",
    quote: "...Me encanta atenderme con Leila porque soy muy sensible a las energías, y cuando ella me atiende suelo sentir mucho alivio en mi cuerpo físico y suelo ver muchas cosas lindas en mi interior.",
    name: "Alejandro Pappalardo"
  },
  {
    id:3,
    img: "/images/testimonies/testimonios_eugenio_madero-150x150.png",
    quote: "...No se puede comparar con otras terapias de la psiquis. Acelera procesos internos, potencia incipientes cambios que se han iniciado.",
    name: "Eugenio Madero"
  },
  {
    id:4,
    img: "/images/testimonies/testimonios_juan_ignacio_dagostino-150x150.png",
    quote: "...Tiene una amplia variedad de recursos y capacidades los cuales considero muy preciados ya que no son fáciles de desarrollar.",
    name: "Juan Ignacio D'Agostino"
  },
  {
    id:5,
    img: "/images/testimonies/testimonios_leticia-150x150.png",
    quote: "...Brindó mucha luminosidad a mi rostro, una apariencia más armoniosa, y frescura en el semblante. Muchas gracias Leila por todo!",
    name: "Leticia"
  },
  {
    id:6,
    img: "/images/testimonies/testimonios_roman_kierzenbaum-150x150.png",
    quote: "...Te otorga un nivel de conciencia superior con uno mismo y con el mundo que te rodea además de ser útil y beneficioso en muchos aspectos de la vida diaria.",
    name: "Román Kierzenbaum"
  },
])

export default Testimonials;