import React from "react";

const Services = React.createContext([
  {
    id: 1,
    title: "Plan para deportistas",
    url: "deportistas",
    categoryColor: "mentorias",
    shortDescription:
      "Un plan integral, armado especialmente para deportistas con el objetivo de mejorar la performance en el aspecto físico, emocional y psíquico para conseguir elevar el rendimiento, permitiéndole alcanzar sus objetivos.",
    img: "/images/services-header/crossfit-534615_1920.jpg",
    fullContent: `<h2>¿Cuál es nuestro objetivo?</h2>
    <div class="line"></div>
    
    <p class="justify">
      Nuestro objetivo es mejorar la performance del deportista en el aspecto
      físico, emocional y psíquico, consiguiendo así elevar el rendimiento y
      permitiéndole alcanzar sus objetivos. La metodología se realiza a través de
      sesiones energéticas, con protocolos estudiados que pueden ser adaptados a las
      necesidades particulares de cada deportista.
      <br />
      <br />
      Leila Kattler habiendo sido deportista y competidora en alto rendimiento,
      lleva más 14 años de estudio de la técnica. Durante 9 años ha estudiado el
      impacto, puesto a prueba en su propio cuerpo y ha aplicado en otros
      deportistas la eficacia de determinados protocolos, adaptados a cada
      disciplina y cuerpo, entendiendo las necesidades de cada uno de los que se ha
      acercado, pudiendo acompañar y cumplir los sueños y expectativas de los
      mismos.
    </p>
    
    
    
    <h2>Plan para deportistas</h2>
    <div class="line"></div>
    <h3>¿Que abarca?</h3>
    
    <ol class="list-int-num">
      <li>Recuperación de lesiones (desgarros, esguinces, fracturas, distenciones, etc)</li>
    
      <li>Incremento del poder físico y psicoemocional.</li>
    
      <li>
        Recuperación en el derecho del disfrute en lo que hacen, buscando poner en
        primera instancia la pasión.
      </li>
      <li>
        Incremento en la sincronicidad y el entendimiento subjetivo entre sus
        compañeros de equipo
      </li>
    </ol>
    
    <h3>Incremento del poder físico y psicoemocional</h3>
    <p class="justify">
      Buscaremos potenciar aún más tus habilidades físicas ya conseguidas para
      alcanzar un nivel elite. Mientras que en el aspecto psicoemocional
      trabajaremos los miedos, o posibles traumas. Los patrones mentales que
      bloquean la salida a tu Ser exitoso. Las inseguridades y/o ansiedades al
      momento de competir. Las dudas sobre tí mismo, etc
    </p>
    
    <h3>
      Incremento en la sincronicidad y el entendimiento subjetivo entre sus
      compañeros de equipo
    </h3>
    <p class="justify">
      Si juegas en equipo, muchas veces dependiendo de cómo estemos, nos cuesta ese
      lenguaje silencioso de conexión donde podemos realizar la jugada en un
      perfecto tiempo en ese espacio. Con este trabajo podrás desarrollar
      percepciones espaciales y de lecturas en el transcurso del partido que te
      llevarán a anticipar jugadas de otros y a un mejor trabajo en equipo para
      alcanzar su meta grupal...ganar.
    </p>
    
    <h2>Plan para clubes</h2>
    <div class="line"></div>
    
    <p class="justify">Si sos entrenador y buscas un equipo sincronizado entre sus miembros, con un
        gran empuje desde su pasión, que despierte (no solo contento entre los miembros,
        sino también entre el público) y admiración, este plan es para vos. Junto al
        equipo técnico ya conformado, sumar este plan potenciará y acortará los plazos
        que se trabajan comúnmente.</p>
    
    
    <h3>¿Cómo?</h3>
    <ul class="list-int">
      <li>
        Trabajando en el subconsciente psíquico de cada jugador, fortaleciendo la
        mente y el poder en la excelencia
      </li>
      <li>Incrementando del poder físico y psicoemocional de cada jugador.</li>
      <li>
        incrementando la sincronicidad y el entendimiento subjetivo entre sus
        compañeros de equipo
      </li>
      <li>
        Trabajando en los objetivos dispuestos por el club, buscando que las
        energías favorables se manifiesten en los plazos estipulados.
      </li>
    </ul> 
    <a href="https://tureservaonline.app/leila-kattler" target="_blank" class='primary-button'>Agendá tu turno</a>   
    `,
    link: "/servicios/deportistas",
    mostrequested: false,
  },
  {
    id: 2,
    title: "Plan para negocios y empresas",
    url: "negocios-empresas",
    categoryColor: "mentorias",
    shortDescription:
      "Un negocio o un proyecto, es un ser energético, tiene  vida. Con este plan podemos ayudarte a que crezca próspero, exitoso y saludable.",
    img: "/images/services-header/job-5382501_1920.jpg",
    fullContent: `<h2>¿Por qué contratar este plan?</h2>
    <div class="line"></div>
    
    <p class="justify">
    Un negocio, un proyecto, es un ser energético, tiene  vida.
    Con este plan podemos ayudarte a que crezca próspero, exitoso y saludable.
    <br />
    <br />
    
    Si llegaste hasta acá, quiere decir que tu conciencia sobre el dinero y el éxito es muy grande.<br />
    Siempre nos hablan de que el dinero es energía, de la actitud que debemos tener para afrontar un negocio o proyecto en el contexto actual.
    Pero aunque esto es cierto, no es el 100% para que nos vaya bien. ¿Porqué pasa esto? <br />
    <ol class="list-int-num">
      <li>El éxito depende en parte, de qué tan saludables estemos en varios aspectos de la vida. (física, emocional, mental y financieramente)</li>
    
      <li>Nuestra empresa o proyecto, al momento de comenzar a trabajarlo, ha nacido en el plano material - concreto.</li>
    </ol> 
    </p>
    <h3>¿Cómo?</h3>
    <ul class="list-int">
      <li>
      Tendremos una primer reunión on line para conocer tu empresa y el estado actual. 
      </li>
      <li>Luego se desarrollará un plan personalizado basado en los objetivos establecidos a corto, mediano y largo plazo.</li>
      <li>
      Reuniones semanales por video llamada para conocer la situación actual de los proyectos.
      </li>
      <li>
      Trabajaremos con el equipo o cabeza que dirige la empresa y/o proyecto.
      </li>
    </ul>   
    <p><b>No esperes más. </b></p> 
    <a href="https://tureservaonline.app/leila-kattler" target="_blank" class='primary-button'>Agendá tu turno</a>
    `,
    link: "/servicios/negocios-empresas",
    mostrequested: true,
  },
  {
    id: 3,
    title: "Feng shui",
    url: "feng-shui",
    categoryColor: "espiritualidad",
    shortDescription:
      "Muchas veces venimos haciendo las cosas bien, pero sentimos que avanzamos lento o con mucho esfuerzo a nivel físico y|o psicoemocional; y no es que las situaciones no sean propicias, sino que hay un desorden energético que no coincide con nuestras proyecciones en los lugares que habitamos.",
    img: "/images/services-header/room-1336497_1920.jpg",
    fullContent: `<h2>¿Qué es el Feng Shui?  </h2>
    <div class="line"></div>
    <p class="justify">El Feng Shui nos permite resolver las condiciones incorrectas de su hogar, oficina o negocio.
    Muchas veces venimos haciendo las cosas bien, pero sentimos que avanzamos lento o con mucho esfuerzo a nivel físico y|o psicoemocional; y no es que las situaciones no sean propicias, sino que hay un desorden energético que no coincide con nuestras proyecciones en los lugares que habitamos.
    La disposición de puertas, artefactos, baños, escritorios, respaldos de camas, techos, espejos de sus hogares y oficinas son determinantes de la buena salud y del éxito en los negocios y las relaciones. La ubicación y la forma de su casa y su terreno afecta la energía de su hogar o comercio.
    El Feng Shui facilita los procesos de ascenso en la vida con un menor esfuerzo. Ayuda a un ascenso como si estuviéramos en una escalera mecánica.</p>
    <a href="https://tureservaonline.app/leila-kattler" target="_blank" class='primary-button'>Agendá tu turno</a>`,
    link: "/servicios/feng-shui",
    mostrequested: false,
  },
  {
    id: 4,
    title: "Registros Akáshikos",
    url: "registros",
    categoryColor: "espiritualidad",
    shortDescription:
      "Cuando te sientes sin rumbo y completamente perdido. Cuando a lo largo de tu vida se repiten situaciones en tu línea familiar. Cuando no estás seguro de si las decisiones que estás tomando son las correctas, éste puede ser el camino.",
    img: "/images/services-header/int_header_reg_aka.jpg",
    fullContent: `<div class="texto-info">
    <h2>¿Qué son los registros akashicos?</h2>
    <div class="line"></div>
    <p class="justify">
      El <b>Registro Akáshico</b> es el Libro de la Vida del que hablan las
      religiones. El mismo se encuentra en custodia de los seres de luz que
      se llaman Kumaras (Guardián), son los Guardianes del Registro
      Akáshico. El vórtice de energía terrestre que los contacta está en
      <b>Shambala</b>, en el Tibet. <br /><br />
      <b>AKASHA</b> es una palabra de origen sánscrito, que se utiliza para
      denominar un plano de la conciencia cósmica que actúa como archivo, en
      el cual se graban o registran todos los eventos, situaciones,
      pensamientos, emociones y acciones de un ser. Allí esta registrada
      toda la historia del planeta, así como toda la historia personal de
      cada uno de nosotros. Se halla escrito el propósito en la Vida, así
      como el programa de nuestro destino futuro según nuestro karma o
      aprendizaje. Todo es energía en el Universo. Esta energía vibra en
      diferentes frecuencias y dimensiones. Estas dimensiones están
      configuradas por espacios infinitos donde existen mundos de otros
      niveles no materiales sino sutiles. Ellos se llaman Tattwas y son los
      cinco elementos a través de los cuales se distribuye la Energía
      Planetaria: Tierra, Agua, Aire, Fuego y Akasha.<br /><br />
      El único vehículo humano para alcanzar todos los niveles de
      frecuencias es el Alma, que es a su vez vehículo del Espíritu.<br />
      Las memorias que se encuentran en este espacio de conciencia cósmica,
      revelan situaciones que en el presente ayudan a esclarecer el por qué
      de nuestras elecciones, experiencias de vida, vínculos, pues están
      formadas por una multitudinaria masa de información acumulada
      encarnación tras encarnación.<br /><br />
      Lo que distingue esta lectura de otras técnicas es que, el trabajo de
      los Registros Akáshicos es de gran profundidad para aquellas personas
      que están dispuestas a “mirarse en el espejo del Alma”, para remover ,
      liberar y sanar todos los Acuerdos y Contratos Almicos, muchas veces
      alojados en nuestra memoria celular, revisar las situaciones kármicas
      y conocer el propósito de nuestra vida para nuestro Mayor Bien y de
      todos los que nos rodean.
    </p>
  </div>
  <div class="texto-info">
    <h2>Guia de Consulta</h2>
    <div class="line"></div>
    <p class="justify">
      Para obtener el máximo beneficio durante una consulta, defina los
      temas más importantes para usted en este momento y a continuación
      anote entre 3 y 5 preguntas específicas relacionadas con el tema. De
      esta manera le será más fácil dirigir la consulta. Dé prioridad a sus
      preguntas para asegurarse de que pregunte lo más importante en primer
      lugar.<br /><br />
      Aunque hay muchos tipos de preguntas para hacer siempre es la
      “intención” detrás de la pregunta la que revela la vibración de la
      misma.
      Pregúntese qué es lo que está buscando saber, sobre qué necesita guía
      y dirección, sobre qué necesita claridad en cuanto al tema de la
      pregunta.<br /><br />
      Sepa que puede haber ocasiones en que no reciba directamente una
      respuesta a sus preguntas. Recuerde que son sus Guías y Maestros
      quienes determinan que es lo mejor para usted y que esto no interfiera
      con su libre albedrío. Medite sobre la posibilidad de que algunas
      cosas no esta preparado para saber en el contexto del momento actual
      que está transitando. Confíe en la sabiduría divina que le
      proporcionará la información más propicia.<br /><br />
      El registro no va a decirte que hacer porque tu libre albedrío es
      inviolable, te dirán en cambio, que es lo mejor para tu crecimiento en
      todos los niveles de la vida que has escogido vivir.<br /><br />
      También tenga en cuenta que puede pedir a sus Guías y Maestros que le
      recomienden que temas abordar, qué más debería preguntar, si hay algo
      que no está viendo y es necesario que aborde en la consulta, o si hay
      algo que le conviene que pregunte.<br /><br />
      Si desconoce alguna definición como Karma, vida pasada, alma gemela,
      etc. pida información sobre estos conceptos durante la lectura antes
      de formular una pregunta usando estos términos. De este modo tendrá un
      mejor entendimiento al recibir una respuesta. Considere posible que lo
      que usted cree entender no necesariamente sea correcto.<br /><br />
      El registro también educa, puedes pedir a tus Maestros y Guías que te
      enseñen un concepto o que te ayuden a corregir una idea equivocada que
      tengas sobre algún tema. Muchas veces el ego siente que sabe con
      certeza algo, y la realidad es otra muy distinta.
    </p>
  </div>
  <a href="https://tureservaonline.app/leila-kattler" target="_blank" class='primary-button'>Agendá tu turno</a>`,
    link: "/servicios/registros",
    mostrequested: true,
  },
  {
    id: 5,
    title: "Terapia energética",
    url: "terapia-energetica",
    categoryColor: "clinica",
    shortDescription:
      "Es un revolucionario y completo sistema de técnicas naturales de sanación que utiliza el prana o energía vital para armonizar el cuerpo energético del ser humano y su entorno, recuperando la salud física, emocional, mental y material.",
    img: "/images/services-header/int_header_sanac1.jpg",
    fullContent: `      <div class="texto-info">
    <h2>¿Qué compone la terápia energética?</h2>
    <div class="line"></div>
    <p class="justify">
      Es un revolucionario y completo sistema de técnicas naturales de
      sanación que utiliza el prana o energía vital para armonizar el cuerpo
      energético del ser humano y su entorno, recuperando la salud física,
      emocional, mental y material. <br />
      Esta terapia, complementa y apoya la acción de las prácticas
      tradicionales terapéuticas(medicina), hoy en día es practicada tanto por
      médicos, que la combinan con sus tratamientos ortodoxos. <br />
      Cuando el cuerpo está enfermo o sufriendo, la fuerza de vida y el
      campo energético del cuerpo están afectados. Con esta terapia
      podemos limpiar el aura y los chakras de la persona enferma haciendo
      que el cuerpo energético se recargue y fortalezca, reponiendo de esta
      manera la vitalidad del organo, el cuerpo físico, emocional y mental
      según sea necesario.<br />
      El toque físico no es necesario porque el terapeuta trabaja en el
      cuerpo energético, donde la dolencia aparece primero. El proceso de
      limpieza y energización es tan simple que puede ser fácilmente
      aprendido y practicado por cualquier persona.<br />
    </p>
  </div>
  <div class="texto-info">
    <h2>Terapia complementaria</h2>
    <div class="line"></div>
    <p class="justify">
      Esta terapia no pretende reemplazar la medicina ortodoxa sino
      complementarla. Esta forma de trabajo produce mayor eficacia y rapidez al momento de buscar volver a recuperar la salud
    </p>
  </div>
  <div class="texto-info">
    <h2>Sanación Física</h2>
    <div class="line"></div>
    <h3>¿Qué dolencias se pueden trabajar con la sanación física?</h3>
    <p class="justify">
      <b
        >Sistema inmunológico y linfático, infecciones e inflamaciones,
        fiebre, debilitamiento, etc.)</b
      ><br />
    </p>
    <ul class="list-int">
      <li>
        Afecciones de ojos, oídos y garganta (glaucoma, cataratas, sordera,
        acufenos, vértigos, etc.)<br />
      </li>
      <li>
        Afecciones de la piel (eczemas, acné, alergias, psoriasis, etc.)<br />
      </li>
      <li>
        Enfermedades cardíacas y circulatorias (afecciones de la arteria
        coronaria, congénitas, hipertensión, apoplejía, etc.) <br />
      </li>
      <li>
        Afecciones Gastrointestinales(vómito, diarrea, estreñimiento,
        hemorroides, hernia de hiato, colesterol alto, cálculos biliares,
        hepatitis, problemas de asimilación, pancreatitis, colitis, colon
        irritable, etc.) <br />
      </li>
    </ul>
    <p>
      <b
        >Enfermedades del sistema urinario (cálculos renales, regeneración
        de riñones, etc.)</b
      >
      <br />
    </p>
    <div class="line3"></div>
    <ul class="list-int">
      <li>
        Trastornos del sistema respiratorio (asma, tos crónica, bronquitis,
        resfriados, etc.)<br />
      </li>
      <li>
        Afecciones del sistema reproductor (Impotencia, dismenorrea,
        irregularidad menstrual, cistitis, próstata, etc.)<br />
      </li>
      <li>
        Afecciones endocrinas (diabetes, hipertiroidismo, alteración de las
        glándulas, etc.)<br />
      </li>
    </ul>
    <p>
      <b
        >Desórdenes musculares y esqueléticos (escoliosis, artritis,
        tortícolis, bursitis, calambres, lesiones deportivas,
        ciática, hernia, esguinces, desgarros, etc.)</b
      ><br />
    </p>
    <div class="line3"></div>
    <ul class="list-int">
      <li>
        Desórdenes de la sangre(anemia, afecciones autoinmunes, etc.)<br />
      </li>
      <li>
        Trastornos del cerebro y del sistema nervioso (parálisis facial,
        tics, retardo mental, Parkinson, meningitis, déficit de atención e
        hiperactividad (TDH y TDHA), estrés, depresión, etc.)<br />
      </li>
    </ul>
  </div>
  <div class="texto-info">
    <h2>Salud emocional y mental</h2>
    <div class="line"></div>
    <p class="justify">
      La atención se centran en limpiar las formas de energía que nos
      distorsionan y alteran, y luego aporta energía que refuerza las
      estructuras sutiles, las auras y los chakras. De ese modo se elimina
      todo aquello que nos lleva a pensar, sentir y actuar de formas
      desagradables y disonantes y se refuerza energéticamente la parte del
      comportamiento que se quiere potenciar.<br />
      La Sanación Energética para sanar la mente se enfoca en el tratamiento
      de los trastornos y de las enfermedades mentales sin entrar en el
      análisis de las experiencias y de los recuerdos dolorosos.
    </p>
    <h3>¿Qué dolencias se pueden trabajar a nivel psico-emocional?</h3>
    <ul class="list-int">
      <li>Estrés, irritabilidad, ansiedad, pesar, histeria…<br /></li>
      <li>Fobias, traumas, obsesiones y compulsionzes. <br /></li>
      <li>Tabaquismo, alcoholismo, drogadicción.<br /></li>
      <li>Depresión, causada por factores físicos o psicológicos.<br /></li>
      <li>
        Sanación de comportamientos, agresivos, violentos y paranoicos.<br />
      </li>
      <li>Trastorno de Hiperactividad (THA)<br /></li>
    </ul>
  </div>
  <a href="https://tureservaonline.app/leila-kattler" target="_blank" class='primary-button'>Agendá tu turno</a>`,
    link: "/servicios/terapia-energetica",
    mostrequested: false,
  },
  {
    id: 6,
    title: "Veterinaria Pránica",
    url: "veterinaria-pranica",
    categoryColor: "clinica",
    shortDescription:
      "La Veterinaria Pránica es una disciplina que se enfoca en aplicar los principios de la sanación energética a los animales.",
    img: "/images/services-header/int_header_vet1.jpg",
    fullContent: `<div class="texto-info">
    <h2>¿Qué es la Veterinaria Pránica?</h2>
    <div class="line"></div>
    <p class="justify">
      La Veterinaria Pránica es una disciplina que se enfoca en aplicar los
      principios de la sanación energética a los animales. Los profesionales
      especializados en veterinaria pránica trabajan con el campo energético de
      los animales utilizando técnicas que buscan limpiar y equilibrar dicho
      campo. Esto incluye la eliminación de bloqueos energéticos y la aplicación
      de energía vital para facilitar el proceso de sanación.
      <br />
      <br />
      La veterinaria pránica se considera un complemento a los cuidados
      veterinarios convencionales, y se utiliza para ayudar en la reducción del
      estrés, acelerar la recuperación de enfermedades o lesiones, fortalecer el
      sistema inmunológico y promover un estado de equilibrio y armonía en los
      animales.
      <br />
      <br />
      Nuestro equipo veterinario se especializa constantemente y durante el
      tratamiento contarán con el acompañamiento de Leila Kattler, sus
      conocimientos y clarividencia para una mejor atención y resultados.
      <br />
      <br />
      Es importante destacar que la veterinaria pránica no reemplaza los
      tratamientos veterinarios tradicionales, sino que se utiliza de manera
      complementaria.
    </p>
  </div>
  <a
    href="https://tureservaonline.app/leila-kattler"
    target="_blank"
    class="primary-button"
    >Agendá tu turno</a
  >  
  `,
    link: "/servicios/veterinaria-pranica",
    mostrequested: true,
  },
  
]);

export default Services;
