import Menu from "../../components/Menu";
import "./styles.scss";
import Footer from "../../components/Footer";

export default function Reservation() {
  return (
    <>
      <Menu />
      <main id="reservation">
        <div>
          <h1>Sesiones online</h1>
          <p>
            Aquí podrás agendar un encuentro con Leila, para poder comenzar a
            trabajar junto con ella tu camino de transformación interior y
            alcanzar ese balance que buscás en tu vida.
          </p>
        </div>
        <iframe
          src="https://tureservaonline.com.ar/leila-kattler?iframe=1"
          style={{
            maxWidth: "80vw",
            height: "600px",
            maxHeight: "75vh",
            width: "100%",
          }}
        ></iframe>
      </main>
      <Footer />
    </>
  );
}
