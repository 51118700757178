import React from 'react'
import { Link } from 'wouter'
import './styles.scss'

export default function Button({link, text, target='_self'}) {
    return(
        <Link to={link} className='primary-button'>
        {text}
        </Link>
    )
}