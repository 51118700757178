import React, { useEffect, useState } from "react";
import { Link } from "wouter";
import Button from "../Button";
import "./style.scss";

export default function Menu() {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <nav>
        <div className="logoContainer">
          <Link to="/">
          <img
            className="logoMobile"
            src="../images/logo/002-Leila Kattler_LogoPrincipal.png"
            alt="logo"
          />
          <img className="logo" src="../images/logo/002-Leila Kattler_LogoPrincipal.png" alt="logo" />
          </Link>
        </div>
      
      <div className="vertical-menu">
        <button
          title="Menu"
          id="btn"
          className="btn"
          onClick={toggleMenu}
        >
          <i className="fas fa-bars"></i>
        </button>
        {(windowSize.innerWidth >= 1200 || openMenu) && <div className="secciones" id="secciones">
          <div className="topMenu">
            <button
              title="Cerrar Menu"
              id="cancel"
              className="cancel"
              onClick={toggleMenu}
            >
              <i className="fas fa-times"></i>
            </button>
            <img
              className="insidelogo"
              src="../images/logo/006-Leila Kattler_Simbolo.png"
              alt="logo"
            />
          </div>
          <ul className="menu">
            <li className="seccion" id="inicio">
              <Link className="seccion" to="/">
                Inicio
              </Link>
            </li>
            <li className="seccion">
              <Link className="seccion" to="/equipo">
                Equipo
              </Link>
            </li>
            <li className="seccion">
              <Link className="seccion" to="/servicios">
                Servicios
              </Link>
            </li>
            <li className="seccion">
              <Link className="seccion" to="/contacto">
                Contacto
              </Link>
            </li>
            <li className="seccion">
              <a href="https://tureservaonline.app/leila-kattler" target="_blank" className='primary-button'>Agendá tu turno</a>
            </li>
          </ul>
          <div className="redes">
            <div className="red_full">
            <a
            className="link_red"
            aria-label="Chat on WhatsApp"
            href="https://wa.me/541134281243"
            target="_blank"
          >
            <i class="fab fa-brands fa-whatsapp fa-lg" style={{color: "#ffffff;"}}></i>
          </a>
            </div>
            <div className="red_full">
              <a
                className="link_red"
                href="https://www.instagram.com/leilakattler/"
                target="_blank"
                aria-label="Instagram"
                rel="noreferrer"
              >
                <i className="white fab fa-instagram"></i>
              </a>
            </div>
            {/* <div className="red_full">
              <a
                className="link_red"
                href="https://www.youtube.com/channel/UCsdqgaBMKvGHzUS-FDx5fKg"
                target="_blank"
                aria-label="Youtube"
                rel="noreferrer"
              >
                <i className="white fab fa-youtube"></i>
              </a>
            </div> */}
          </div>
        </div>}
        
      </div>
    </nav>
  );
}
