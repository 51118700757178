import React from "react";
import Button from "../Button";
import "./styles.scss";

export default function ServiceCard({ title, category, img, link, isHidden }) {
  let color;
  switch (category) {
    case "espiritualidad":
      color ='#8173C6'
      break;
    case "mentorias":
      color = '#AA1313'

      break;
    case "clinica":
      color = '#9ECAEF'

      break;
    default:
      color = '#896620'

      break;
  }
  let imageContainerStyle = {
    backgroundImage: `url(${img})`,
    borderBottom: `0.7rem solid ${color}`,
  };
  let cardStyle ={
    backgroundImage: `linear-gradient(to top, transparent, transparent 50%, ${color} 50%, ${color})`,
    backgroundPosition: '0% 100%',
    backgroundSize: '100% 200%',
    transition: 'all 0.25s ease-out',
    height: '100%',
  }
  return (
    isHidden ? '' :
    <div className={`service-card ${category}`}>
      <a className="no-style" href={`${link}`} style={cardStyle}>
        <div>
          <div className="img-container" style={imageContainerStyle}></div>
          <div className="text-container" >
            <h4>{title}</h4>
          </div>
        </div>
      </a>
    </div>
  );
}
