import React from 'react'
import { Link } from 'wouter'
import './styles.scss'

export default function SecondaryButton({link, text}) {
    return(
        <Link to={link} className='secondary-button'>
        {text}
        </Link>
    )
}