import Menu from "../../components/Menu";
import "./styles.scss";
import Services from "../../context/services";
import { useContext } from "react";
import Footer from "../../components/Footer";
import SecondaryButton from "../../components/SecondaryButton";
import PackCard from "../../components/PackCard";
import Packs from "../../context/packs";

export default function ServiceDetail({ params }) {
  let contextServices = useContext(Services);
  let service = contextServices.filter((serv) => serv.url === params.service);
  let contextPack = useContext(Packs);

  return (
    <>
      <Menu />
      <header
        id="servicio-header"
        style={{ backgroundImage: `url(${service[0].img})` }}
      >
        <div className="text-content">
          <h1>{service[0].title}</h1>
        </div>
      </header>
      <div className="prev-button">
        <SecondaryButton
          link={"/servicios"}
          text={"< Volver a los servicios"}
        />
      </div>
      {/* <div className="packs-container">
      {params.service === 'terapia-energetica' && contextPack.map((pack) => (
        <PackCard key={pack.id} title={pack.name} descr={pack.description} price={pack.price}/>
        ))}
      </div> */}
      <main dangerouslySetInnerHTML={{ __html: service[0].fullContent }}></main>
      <Footer />
    </>
  );
}
