import Menu from "../../components/Menu";
import "./styles.scss";
import Services from "../../context/services";
import { useContext, useEffect, useState } from "react";
import ServiceCard from "../../components/ServiceCard";
import Footer from "../../components/Footer";

export default function Servicios() {
  let context = useContext(Services);
  let [selectedCategory, setSelectedCategory] = useState('todos')

  return (
    <>
      <Menu />
      <header id="servicios-header">
        <div className="text-content">
          <h1>servicios</h1>
          <p className="subtitle">
          Atención personalizada
          </p>
        </div>
      </header>
      <nav className="filtros-servicios">
        <p>Filtros:</p>
        <ul>
        <li className="filtro-todos" onClick={() => setSelectedCategory('todos')}><button>Todos</button></li>
        <li className="filtro-mentorias" onClick={() => setSelectedCategory('mentorias')}><button>Mentorías</button></li>
        <li className="filtro-espiritualidad" onClick={() => setSelectedCategory('espiritualidad')}><button>Espiritualidad</button></li>
        <li className="filtro-clinica" onClick={() => setSelectedCategory('clinica')}><button>Clínica</button></li>
        </ul>
      </nav>
      <section id="servicios">
        <div className="services-container">
          {context.map((service) => (
              <ServiceCard key={service.id} title={service.title} category={service.categoryColor} img={service.img} link={service.link} isHidden={selectedCategory == 'todos' || service.categoryColor == selectedCategory ? false : true}/>           
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
