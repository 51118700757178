import React from "react";

const Packs = React.createContext([
  {
    id:1,
    name: "Plan Premium",
    description: `<ul><li><b>8</b> Sesiones</li><li>El valor es mensual</li><li>Podés dar de baja el plan cuando quieras</li><li>¡Vas a estar <b>ahorrando $3000</b> por sesión!</li></ul> <p class="undertext">*Los valores son en pesos Argentinos. En caso de vivir fuera de Argentina consulte los precios.</p>`,
    price: "$24000"
  },
  {
    id:2,
    name: "Plan Gold",
    description: `<ul><li><b>4</b> Sesiones</li><li>El valor es mensual</li><li>Podés dar de baja el plan cuando quieras</li><li>¡Vas a estar <b>ahorrando $2500</b> por sesión!</li></ul> <p class="undertext">*Los valores son en pesos Argentinos. En caso de vivir fuera de Argentina consulte los precios.</p>`,
    price: "$14000"
  },
  {
    id:3,
    name: "Plan Silver",
    description: `<ul><li><b>2</b> Sesiones</li><li>El valor es mensual</li><li>Podés dar de baja el plan cuando quieras</li><li>¡Vas a estar <b>ahorrando $2000</b> por sesión!</li></ul> <p class="undertext">*Los valores son en pesos Argentinos. En caso de vivir fuera de Argentina consulte los precios.</p>`,
    price: "$8000"
  },
  {
    id:4,
    name: "Una sesión",
    description: `<p>Las sesiones de sanación son con modalidad online y tienen una duración de <b>1 hora</b>. <br/>¡Probá una ahora e iniciá el cambio!</p><p class="undertext">*Los valores son en pesos Argentinos. En caso de vivir fuera de Argentina consulte los precios.</p>`,
    price: "$6000"
  },
])

export default Packs;