import Menu from "../../components/Menu";
import "./styles.scss";
import Footer from "../../components/Footer";
import Testimonials from "../../context/testimonials";
import { useContext } from "react";
import TestimonialCard from "../../components/Testimonial";

export default function Conoceme() {
  let context = useContext(Testimonials);

  return (
    <>
      <Menu />
      <header id="conoceme-header">
        <div className="text-content">
          <h1>Leila Kattler</h1>
          <p className="subtitle">Conociendo al equipo</p>
        </div>
      </header>
      <main className="conoceme">
        <div className="texto-info">
          <h2>Nuestro equipo</h2>
          <p className="justify">
            Somos un grupo comprometido y apasionado dedicado a brindar un
            enfoque integral para el bienestar y la salud, abordando áreas
            fundamentales de la vida de nuestros pacientes. Con un equipo
            diverso y multidisciplinario, nos esforzamos por ofrecer un servicio
            excepcional y personalizado a cada persona que busca mejorar su
            calidad de vida.
            <br />
            <br />
            Encabezando este equipo se encuentra nuestra fundadora, Leila
            Kattler, una visionaria en el campo de las mentorías y la
            espiritualidad. Con una dedicación incomparable hacia nuestros
            pacientes VIP, Leila aporta una perspectiva única y profunda para
            guiar y acompañar en el camino hacia el crecimiento personal y la
            transformación.
            <br />
            <br />
            En el área clínica, contamos con el invaluable aporte de Alejandro,
            experto en terapias energéticas y veterinaria pránica. Su compromiso
            con la salud integral y su habilidad para aplicar métodos
            innovadores en el cuidado de nuestros pacientes, garantiza un
            enfoque holístico para la mejora de su bienestar físico y emocional.
            <br />
            <br />
            Y por último, pero no menos importante, tenemos a Soledad, nuestra
            dedicada secretaria. Con su amabilidad y atención, Soledad es la voz
            amigable que atiende consultas por WhatsApp y está siempre dispuesta
            a ayudar a los pacientes en la organización de sus citas,
            resolviendo sus dudas y asegurándose de que su experiencia con
            nosotros sea fluida y satisfactoria.
            <br />
            <br />
            En conjunto, nuestro equipo está comprometido con la excelencia, la
            empatía y la atención al detalle para brindar un servicio
            excepcional y respaldar el bienestar integral de cada individuo que
            busca nuestro apoyo. Estamos aquí para acompañarte en tu viaje hacia
            una vida más saludable, plena y equilibrada.
            <br />
            <br />
            ¡Gracias por confiar en nosotros para cuidar de ti!
          </p>
        </div>

        <div className="texto-info">
          <h2>Sobre Leila</h2>
          <p className="justify">
            Con un estudio de más de 16 años en el campo espiritual, y con una
            sed en su propio desarrollo del Ser; Leila Kattler acompaña a
            personas en su proceso de sanación, no solo a nivel Físico y/o
            emocional, sino también a nivel espiritual; buscando que la persona
            pueda llegar a todo su potencial para que lleve una vida más
            saludable, felíz y prospera.
            <br />
            <br />
            Ha trabajado junto a jugadores de Fútbol profesional, músicos de
            renombre internacional, políticos, atletas del alta competencia,
            empresarios, dando a conocer la importancia del cuidado y desarrollo
            de la propia energía vital para poder alcanzar metas y llevar una
            vida feliz y prospera en todos los aspectos de la vida.
          </p>
        </div>

        <div className="texto-info">
          <h3>La historia</h3>
          <p className="justify">
            Leila Kattler desde muy pequeña tuvo un interés innato y fuera de lo
            normal por el mundo espiritual para alguien de temprana edad. Siendo
            educada en colegios católicos, su formación espiritual estuvo
            dirigida hacia la formación dogmática de la Iglesia Católica
            Apostólica Romana. <br />
            <br />
            Durante su adolescencia, sintió un primer llamado a la vida
            religiosa, el cuál no siguió. Sin embargo, a la edad de 24 años,
            resurge este llamado en la mitad de su carrera Universitaria donde
            estudiaba Diseño de Imagen y Sonido en la UBA. En este punto, decide
            escucharse e inicia una orientación vocacional de la mano de una
            terapeuta que no se quedó solo en los clásicos tests, sino que la
            invitó a ahondar aspectos muy profundos de su yo. Durante este
            proceso se vuelve a despertar una cualidad que tenía desde los 4
            años: poder ver el mundo astral. Veía desde seres espirituales de la
            naturaleza, hasta Ángeles y más, todo el tiempo, lo cuál le estaba
            dificultando su vida diaria. En paralelo a esto, acompañada por una
            hermana de la Iglesia, inició su proceso de discernimiento para ver
            si su camino era la vida religiosa. Aconsejada por su orientadora
            vocacional, llega a los cursos del Maestro Choa Kok Sui, dónde
            encuentra en ellos explicación a muchas de las cosas que venía
            viendo del mundo espiritual. Allí pudo desarrollar aspectos de
            investigación importantes para diferentes aspectos de la vida
            diaria. <br />
            <br />
            Abrió una rama para la sanación en animales, asistiendo en
            veterinarias y a animales exóticos. Comenzó el estudio en el deporte
            (ya que en aquel entonces competía a nivel nacional e internacional
            en Taekwondo) facilitando luego a sus alumnos alcanzar medallas.
            Trabajó en un club de tercera división de AFA con jugadores de su
            primer plantel. Esto la condujo a árbitros que solicitaron sus
            servicios tanto de nivel nacional como internacional. Tal es su
            conocimiento y pasión por todo esto que,inspirada por las enseñanzas
            del Maestro Choa Kok Sui, su último gran desarrollo es la Asesoría
            para empresas, donde grupos de música de carácter internacional,
            emprendedores y empresarios ya han probado sus beneficios. <br />
            <br />
            Finalmente, en su camino de discernimiento entendió que el llamado
            que sentía, no era estar dentro de una institución religiosa, sino
            llevar su estudio ecuménico de la espiritualidad en las religiones a
            su vida y la de otras personas. Su camino fue experimentar que es el
            Amor la piedra fundadora de una vida con paz, equilibrio y
            felicidad. Con más de 16 años de estudio en la escuela del Maestro
            Choa Kok Sui, habiendo estudiado antes meditación trascendental,
            Meditación Zen con toque zen y otras, reunió todo en su persona,
            valorando cada enseñanza recibida, acompañando personas de distintas
            partes del mundo hacia su transformación.
            <br />
            <br />
          </p>
        </div>

        <div className="images-container">
          <div className="image-staff Leila-staff">
            <h3>Leila</h3>
          </div>
          <div className="image-staff Alejandro-staff">
          <h3>Alejandro</h3>

          </div>
          <div className="image-staff Soledad-staff">
          <h3>Soledad</h3>

          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
