import "./App.scss";

import { Route } from "wouter";
import Home from "./pages/Home";
import Servicios from "./pages/Servicios";
import ServiceDetail from "./pages/ServiceDetail";
import Conoceme from "./pages/LeilaKattler";
import Contacto from "./pages/Contacto";
import { useEffect } from "react";
import ScrollToTop from "./hooks/ScrollToTop";
import Reservation from "./pages/Reservation";

function App() {
  return (
      <div className="App">
        <ScrollToTop />
        <section className="App-content">
          <Route path="/" component={Home} />
          <Route path="/equipo" component={Conoceme} />
          <Route path="/servicios" component={Servicios} />
          <Route path="/servicios/:service" component={ServiceDetail} />
          <Route path="/contacto" component={Contacto} />
          <Route path="/reservas" component={Reservation} />
        </section>
      </div>
  );
}

export default App;
