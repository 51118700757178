import React from "react";
import "./styles.scss";
import { Link } from "wouter";

export default function Footer() {
  return (
    <footer>
      <div className="menu-footer">
        <ul className="menu-footer">
          <li id="inicio">
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="/equipo">Equipo</Link>
          </li>
          <li>
            <Link to="/servicios">Servicios</Link>
          </li>
          <li>
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
      </div>
      <div className="redes-footer">
        <div className="red_full">
          <a
            className="link_red"
            aria-label="Chat on WhatsApp"
            href="https://wa.me/541134281243"
            target="_blank"
          >
            <i class="fab fa-brands fa-whatsapp fa-lg" style={{color: "#ffffff;"}}></i>
          </a>
        </div>
        <div className="red_full">
          <a
            className="link_red"
            href="https://www.instagram.com/leilakattler/"
            target="_blank"
            aria-label="Instagram"
            rel="noreferrer"
          >
            <i className="white fab fa-instagram fa-lg"></i>
          </a>
        </div>
      </div>
      <div className="derechos-footer">
        <p className="text-centered">
          Leila Kattler © Todos los Derechos Reservados
          <br />
          <br />
          Desarrollado por Guadalupe Ada
        </p>
      </div>
    </footer>
  );
}
